@import "../../../common/scss/global.scss";
@import "../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-react-inputs/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/bootstrap.css";

.loader{
  position: relative;
  width: 250px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-gradient{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: none;
  }

  .loader-clip{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.95;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: none;
  }

  &.loading{
    .loader-gradient,
    .loader-clip{
      display: block;
    }
  }

  & > .image-container{
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    & img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @-ms-keyframes spin {
      from {
          -ms-transform: rotate(0deg);
      } to {
          -ms-transform: rotate(360deg);
      }
  }
  @-moz-keyframes spin {
      from {
          -moz-transform: rotate(0deg);
      } to {
          -moz-transform: rotate(360deg);
      }
  }
  @-webkit-keyframes spin {
      from {
          -webkit-transform: rotate(0deg);
      } to {
          -webkit-transform: rotate(360deg);
      }
  }
  @keyframes spin {
      from {
          transform: rotate(0deg);
      } to {
          transform: rotate(360deg);
      }
  }
}
