@import "../../common/scss/global.scss";

@import "../../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css";
@import "../../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap.css";
@import "../../../node_modules/@syncfusion/ej2-react-inputs/styles/bootstrap.css";
@import "../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/bootstrap.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/bootstrap.css";
@import "../../../node_modules/@syncfusion/ej2-react-popups/styles/bootstrap.css";
@import "../../../node_modules/@syncfusion/ej2-react-calendars/styles/bootstrap.css";

.pick-timeslot{
  .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;

    & .hide{
      display: none;
    }

    & .submit-button{
      flex-direction: column;
      flex-basis: calc( 50% - 10px );
      border-radius: 30px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      max-width: 450px;
      margin: 15px auto 0 auto;
      background: $primary;
      color: $white;

      & .icon{
        font-size: 18px;
        margin-right: 20px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY( -50% );
      }

      & .text{
        align-items: center;
      }

      &:hover{
        background: transparentize( $primary, 0.20);
      }

      &.light{
        background: transparentize( $primary, 0.90);
        color: $primary;

        &:hover{
          background: transparentize( $primary, 0.80);
        }
      }

      &.disabled{
        background: rgba($primary, 0.20);
        color: rgba($primary, 0.40);
      }

      @media only screen and (max-width: 450px) {
        & .text{
          text-align: center;
        }

        & .icon{
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
          margin-right: unset;
        }
      }
    }

    .planned-date {
      flex-grow: 1;
      text-align: center;
      margin: 20px 0 30px 0;

      .date{
        margin-bottom: 5px;
        font-weight: 600;
      }

      .time-wrapper{
        display: flex;
        justify-content: center;
        font-size: 15px;

        & > div{
          margin-right: 5px;
        }
      }
    }

  }
}
