@import "../../common/scss/global.scss";

.app > .overlay .location-page {
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  border-radius: 15px;

  @media only screen and (min-width: 500px) {
    min-height: 580px;
    height: 65vh !important;
  }


  .map-container{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 130px;
    left: 0;
    z-index: 1;

    @media only screen and (max-width: 500px) {
      top: 80px;
    }
  }

  & .content{
    z-index: 2;
    position: relative;
    margin: 20px 20px 0 20px;

    @media only screen and (max-width: 500px) {
      margin: 10px 10px 0 10px;
    }
  }

  & .form{
    padding-top: 50px;
    position: relative;
    max-width: 500px;
    width: 100%;

    &.form-search{
      justify-content: flex-start !important;
      padding-top: 70px;
    }

    & .form-field{
      position: relative;

      .e-valid-input{
        position: relative;
        z-index: 4;
      }
    }

    & .suggestions{
      background: $white;
      padding: 5px 15px;
      margin-top: -5px;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 48px;
      box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
      z-index: 3;
      width: 100%;

      & > div{
        padding: 10px 5px;
        line-height: 1.2;
        cursor: pointer;

        &:hover{
          text-decoration: underline;
        }

        &:not(:last-child){
          border-bottom: 1px solid $lightgrey
        }
      }
    }
  }

  .navigation{
    position: relative;
    z-index: 2;
    margin-bottom: 20px;

    @media only screen and (max-width: 500px) {
      margin-bottom: 10px;
    }

    & .previous-page {
      background: rgba($white, 0.80);
      padding: 5px 10px;

      @media only screen and (max-width: 500px) {
        display: flex;
        padding: 5px 20px;
      }
    }

  }
}
