@import "../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-react-inputs/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-react-popups/styles/bootstrap.css";
@import "../../../../node_modules/@syncfusion/ej2-react-calendars/styles/bootstrap.css";


.dropdown{

  & .e-dropdownlist{
    height: 40px !important;
  }

  & .e-input{
    color: black !important;
    font-weight: 500 !important;
  }

  &.invalid{
    .e-input-group{
      border-color: red !important;
    }
  }
}

.e-dropdownbase .e-list-item {
  font-size: 16px !important;
  padding: 10px 0;
  font-weight: 500 !important;
}
