@import "reset.scss";
@import "../fonts/santral.scss";
@import "../fonts/fontawesome-pro.scss";

$primary: #1d1c1d;
$secondary: #e23888;
$accent: #ef8145;
$lightgrey: #d3d3d3;
$radius: 10px;

$white: #fff;
$black: #160a16;
$green: #5EC44A;

$gradient: linear-gradient(
  90deg,
  #fcd900,
  #f8b053,
  #ee7c44,
  #e94e65,
  #e73084,
  #ce5599,
  #ab71ad,
  #8f84bd,
  #5aa7dd,
  #46c0ed,
  #66c5e0
);

body {
  color: $primary;
  font-family: "Santral";
  font-size: 16px;
}

.btn-primary {
  background: $primary;
  border-radius: 10px;
  padding: 10px;
  border: none;
  color: $white;
  opacity: 1;

  &:hover {
    opacity: 0.9;
  }
}

.e-control,
.e-input {
  font-family: "Santral" !important;
  font-size: 16px !important;
  color: $primary;
  font-weight: 500;
}
