@import "../../../common/scss/global.scss";

.progress{
  margin-bottom: 40px;

  & h1{
    font-weight: 600;
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    line-height: 1.3;

    @media only screen and (max-width: 500px) {
      font-size: 16px;
      margin-bottom: 15px
    }
  }

  .bar {
    height: 2px;
    background: $primary;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > ul {
      display: flex;

      & > li {
        height: 8px;
        width: 8px;
        border-radius: 15px;
        background: $primary;
        margin-top: -3px;

        &:not(:last-child){
          margin-right: 15px;
        }

        & > div {
          display: none;
        }

        &.active {
          background: $secondary;
          width: 10px;
          height: 10px;
          margin-top: -4px;
          position: relative;

          & > div {
            display: block;
            position: absolute;
            bottom: -20px;
            left: 0;
            font-weight: 500;
            width: 150px;
            font-size: 15px;
          }
        }
      }
    }

    // &::before,
    // &::after {
    //   content: "";
    //   position: absolute;
    //   height: 8px;
    //   width: 8px;
    //   border-radius: 15px;
    //   background: $primary;
    //   top: -3px;
    //   left: -3px;
    // }

    &::after {
      left: unset;
      right: -3px;
    }
  }

  & .body{
    margin-top: 45px;
  }
}
