@import "../../../common/scss/global.scss";

.timeslots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ul {
    width: 100%;
    max-height: 60vh;
    max-width: 450px;

    @media only screen and (max-width: 500px) {
      padding: 0;
      max-height: unset;
      margin-bottom: 10px;
      margin-top: 0;
    }

    & > li {
      flex-basis: calc( 50% - 10px );
      background: $primary;
      border-radius: 30px;
      color: $white;
      width: 100%;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      width: 100%;
      position: relative;
      margin-top: 10px;

      & .icon{
        font-size: 18px;
        margin-right: 20px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY( -50% );
      }

      & .greenChoice{
        font-size: 18px;
        right: 20px;
        position: absolute;
        color: $green;
        top: 50%;
        transform: translateY( -50% );
      }

      .moment {
        flex-grow: 1;
        text-align: center;

        .date{
          margin-bottom: 5px;
          font-weight: 600;
        }

        .time-wrapper{
          display: flex;
          justify-content: center;
          font-size: 15px;

          & > div{
            margin-right: 5px;
          }
        }
      }

      @media only screen and (max-width: 500px) {
        padding: 10px;

        & .moment {
          & .date{
            font-weight: 500;
          }
        }
      }

      @media only screen and (max-height: 650px) {
        padding: 7px;
        font-size: 14px;
      }
    }

    @media only screen and (min-width: 501px) {
      &.grid{
        display: flex;
        flex-wrap: wrap;
        max-width: unset;
        margin-right: -10px;

        & > li{
          flex-basis: calc( 50% - 10px );
          flex-grow: 1;
          margin-right: 10px;
        }
      }
    }
  }
}
