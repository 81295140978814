@import "../../../common/scss/global.scss";

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .loader{
    margin-bottom: 15px;
  }

  .title,
  .subtitle,
  .body {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
  }

  .body{
    margin-top: 15px;
  }

  .subtitle{
    margin-top: 15px;
  }

  .title{
    font-weight: 600;
    font-size: 22px;

    & + .subtitle{
      margin-top: 5px;
    }
  }

  .subtitle{
    font-weight: 500;
    font-size: 20px;

    & + .body{
      margin-top: 10px;
    }
  }
}
