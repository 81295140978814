/**
 * Created by Stefan Potuijt || stefan@potuijt.com
 * Date: 10-06-2018
 * Component: Font Santral
 */

@font-face {
    font-family: 'Santral';
    src:url("santral/c63e44ea-5123-4c9b-8cea-b403e35a1dad.eot?#iefix");
    src:url("santral/c63e44ea-5123-4c9b-8cea-b403e35a1dad.eot?#iefix") format("eot"),url("santral/047ca209-0ecf-480c-ab3f-7b0b754e2de4.woff2") format("woff2"),url("santral/b098fe12-4712-4497-965f-6886c44abdf9.woff") format("woff"),url("santral/8609fa43-e3d3-4a89-abdc-e4778ba48382.ttf") format("truetype"),url("santral/6192cab1-bf62-4b02-ac01-8a04c7be46c6.svg#6192cab1-bf62-4b02-ac01-8a04c7be46c6") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Santral';
    src:url("santral/d389f977-ccf9-42d6-94c8-f49efc0ef275.eot?#iefix");
    src:url("santral/d389f977-ccf9-42d6-94c8-f49efc0ef275.eot?#iefix") format("eot"),url("santral/7eeb3856-9984-4c1e-b3a3-05c38844d9d6.woff2") format("woff2"),url("santral/33366f29-2d62-4cc5-af5a-d941b67d61d6.woff") format("woff"),url("santral/45d31da4-bc34-4bd0-94f7-e9d33ad1da1e.ttf") format("truetype"),url("santral/3af060d6-8ae6-4c6a-9821-f9c799f18ce3.svg#3af060d6-8ae6-4c6a-9821-f9c799f18ce3") format("svg");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/7a1eab88-92dd-4c48-b8ba-c8b13393e64d.eot?#iefix");
    src:url("santral/7a1eab88-92dd-4c48-b8ba-c8b13393e64d.eot?#iefix") format("eot"),url("santral/6194aa86-327d-413b-9de0-46de40d443ca.woff2") format("woff2"),url("santral/e2163a10-98e4-46ed-b2ea-b5b9f6214a43.woff") format("woff"),url("santral/74e011fb-15b3-4c03-85b1-572eed7619bf.ttf") format("truetype"),url("santral/ed3d3bdd-e4c0-4eff-9444-0194c12cf286.svg#ed3d3bdd-e4c0-4eff-9444-0194c12cf286") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/a4b4303f-6eab-41d2-9cf5-fd717d0e7ea3.eot?#iefix");
    src:url("santral/a4b4303f-6eab-41d2-9cf5-fd717d0e7ea3.eot?#iefix") format("eot"),url("santral/18ddc1a8-437f-4d16-bfa2-2ecb95dcf5fc.woff2") format("woff2"),url("santral/51835eef-34b7-493d-8c7c-0bb7f6bd55da.woff") format("woff"),url("santral/aa39be5c-088a-4dfb-b7f0-b55b95501bd8.ttf") format("truetype"),url("santral/4119e3eb-ff69-48ed-ad83-7fd2b574d12b.svg#4119e3eb-ff69-48ed-ad83-7fd2b574d12b") format("svg");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/a5ad1144-da5b-4069-8cb4-81a109a654bf.eot?#iefix");
    src:url("santral/a5ad1144-da5b-4069-8cb4-81a109a654bf.eot?#iefix") format("eot"),url("santral/f8bfa935-aa4c-4db9-be5b-64b7d951601c.woff2") format("woff2"),url("santral/f40a9e3f-78d2-42d8-a947-f1e84859212b.woff") format("woff"),url("santral/83d3da77-3798-4767-a818-cbb596bacb98.ttf") format("truetype"),url("santral/5ba3bde0-4628-44c5-9c7f-ae487e4509ed.svg#5ba3bde0-4628-44c5-9c7f-ae487e4509ed") format("svg");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/ca9e7597-8635-4fe3-90db-5f6b7264d9b5.eot?#iefix");
    src:url("santral/ca9e7597-8635-4fe3-90db-5f6b7264d9b5.eot?#iefix") format("eot"),url("santral/70a35074-b196-44f5-91cf-a6be76d4c458.woff2") format("woff2"),url("santral/f1bf8252-34a2-438c-a755-96f06d5f01da.woff") format("woff"),url("santral/e19e69b7-594d-4995-8e3a-7d76b65774bf.ttf") format("truetype"),url("santral/8edf7e4c-4327-4983-8b34-97d6c5840992.svg#8edf7e4c-4327-4983-8b34-97d6c5840992") format("svg");
    font-weight: 200;
    font-style: italic;
}


@font-face {
    font-family: 'Santral';
    src:url("santral/fa62712e-ed4c-4ca0-80cc-82a76296cda4.eot?#iefix");
    src:url("santral/fa62712e-ed4c-4ca0-80cc-82a76296cda4.eot?#iefix") format("eot"),url("santral/f405303b-225b-456d-9723-b1df488846f0.woff2") format("woff2"),url("santral/faeb2b76-033f-4442-bc40-37f76c454eb4.woff") format("woff"),url("santral/b0bf56d0-7728-4d8d-9763-3738c3da0869.ttf") format("truetype"),url("santral/48149f12-f573-4db4-939d-6b88c4f89f96.svg#48149f12-f573-4db4-939d-6b88c4f89f96") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/e55537e6-44df-4d42-930a-689e5075739c.eot?#iefix");
    src:url("santral/e55537e6-44df-4d42-930a-689e5075739c.eot?#iefix") format("eot"),url("santral/1be10ffb-af6c-42ad-964a-f00637a6342f.woff2") format("woff2"),url("santral/438e9193-3afa-455d-87c2-d907958ebbdc.woff") format("woff"),url("santral/17d061a2-629a-4ffb-9380-f55db3d5056c.ttf") format("truetype"),url("santral/617d8f96-8a36-498e-a3de-7e5268596d7f.svg#617d8f96-8a36-498e-a3de-7e5268596d7f") format("svg");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/c63e44ea-5123-4c9b-8cea-b403e35a1dad.eot?#iefix");
    src:url("santral/c63e44ea-5123-4c9b-8cea-b403e35a1dad.eot?#iefix") format("eot"),url("santral/047ca209-0ecf-480c-ab3f-7b0b754e2de4.woff2") format("woff2"),url("santral/b098fe12-4712-4497-965f-6886c44abdf9.woff") format("woff"),url("santral/8609fa43-e3d3-4a89-abdc-e4778ba48382.ttf") format("truetype"),url("santral/6192cab1-bf62-4b02-ac01-8a04c7be46c6.svg#6192cab1-bf62-4b02-ac01-8a04c7be46c6") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/d389f977-ccf9-42d6-94c8-f49efc0ef275.eot?#iefix");
    src:url("santral/d389f977-ccf9-42d6-94c8-f49efc0ef275.eot?#iefix") format("eot"),url("santral/7eeb3856-9984-4c1e-b3a3-05c38844d9d6.woff2") format("woff2"),url("santral/33366f29-2d62-4cc5-af5a-d941b67d61d6.woff") format("woff"),url("santral/45d31da4-bc34-4bd0-94f7-e9d33ad1da1e.ttf") format("truetype"),url("santral/3af060d6-8ae6-4c6a-9821-f9c799f18ce3.svg#3af060d6-8ae6-4c6a-9821-f9c799f18ce3") format("svg");
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: 'Santral';
    src:url("santral/75037de3-f5a6-4e23-bb9e-7ab10eb9a9ee.eot?#iefix");
    src:url("santral/75037de3-f5a6-4e23-bb9e-7ab10eb9a9ee.eot?#iefix") format("eot"),url("santral/eb3b5069-e24c-450f-8234-15036a5c9e8a.woff2") format("woff2"),url("santral/93b08e64-005a-4359-9062-a22564472854.woff") format("woff"),url("santral/593a544e-fbf6-4c30-ac86-461f3a025dfe.ttf") format("truetype"),url("santral/1e5c15a3-5b86-43ff-b148-8940b4386f15.svg#1e5c15a3-5b86-43ff-b148-8940b4386f15") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/db764470-08c8-4196-8084-c1b26d1b182d.eot?#iefix");
    src:url("santral/db764470-08c8-4196-8084-c1b26d1b182d.eot?#iefix") format("eot"),url("santral/93e2130a-43b8-41e5-8a2d-36ec6383bf59.woff2") format("woff2"),url("santral/b25abc46-acba-45b5-a3a1-1e579dde8bf4.woff") format("woff"),url("santral/9f745cc2-7afa-4e26-8bf3-6d63e583ca55.ttf") format("truetype"),url("santral/b23c6d8d-ad32-4639-a25b-a1efd30ca6f3.svg#b23c6d8d-ad32-4639-a25b-a1efd30ca6f3") format("svg");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/6f1bc556-e27a-4319-9027-9317413ad35b.eot?#iefix");
    src:url("santral/6f1bc556-e27a-4319-9027-9317413ad35b.eot?#iefix") format("eot"),url("santral/3c91a937-afa7-460c-b80b-c87168f7ef94.woff2") format("woff2"),url("santral/35c6f44e-154b-4014-84b1-8f92caba3a75.woff") format("woff"),url("santral/12cc9476-3670-4bfd-ab87-fcb22de40a52.ttf") format("truetype"),url("santral/3229f18b-87ec-4ad6-8b58-7297310ea75e.svg#3229f18b-87ec-4ad6-8b58-7297310ea75e") format("svg");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/275536a7-d305-479b-9b50-fa32601f78d9.eot?#iefix");
    src:url("santral/275536a7-d305-479b-9b50-fa32601f78d9.eot?#iefix") format("eot"),url("santral/9722b951-c927-407d-bffb-ed6bcebde49e.woff2") format("woff2"),url("santral/8b3f2eb0-02bf-468d-8733-a8ba0ae123eb.woff") format("woff"),url("santral/73d68372-21ee-417b-bf75-aecb78e01128.ttf") format("truetype"),url("santral/39390975-dc5c-47a7-826b-314486b0487e.svg#39390975-dc5c-47a7-826b-314486b0487e") format("svg");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/7a1eab88-92dd-4c48-b8ba-c8b13393e64d.eot?#iefix");
    src:url("santral/7a1eab88-92dd-4c48-b8ba-c8b13393e64d.eot?#iefix") format("eot"),url("santral/6194aa86-327d-413b-9de0-46de40d443ca.woff2") format("woff2"),url("santral/e2163a10-98e4-46ed-b2ea-b5b9f6214a43.woff") format("woff"),url("santral/74e011fb-15b3-4c03-85b1-572eed7619bf.ttf") format("truetype"),url("santral/ed3d3bdd-e4c0-4eff-9444-0194c12cf286.svg#ed3d3bdd-e4c0-4eff-9444-0194c12cf286") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Santral';
    src:url("santral/a4b4303f-6eab-41d2-9cf5-fd717d0e7ea3.eot?#iefix");
    src:url("santral/a4b4303f-6eab-41d2-9cf5-fd717d0e7ea3.eot?#iefix") format("eot"),url("santral/18ddc1a8-437f-4d16-bfa2-2ecb95dcf5fc.woff2") format("woff2"),url("santral/51835eef-34b7-493d-8c7c-0bb7f6bd55da.woff") format("woff"),url("santral/aa39be5c-088a-4dfb-b7f0-b55b95501bd8.ttf") format("truetype"),url("santral/4119e3eb-ff69-48ed-ad83-7fd2b574d12b.svg#4119e3eb-ff69-48ed-ad83-7fd2b574d12b") format("svg");
    font-weight: 700;
    font-style: italic;
}
