@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css";

.text-box{

  & .e-textbox{
    height: 40px !important;
    color: black !important;
    font-weight: 500 !important;
  }

  &.invalid{
    .e-input-group{
      border-color: red !important;
    }
  }
}
