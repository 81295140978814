@import "../../common/scss/global.scss";

.create-task-page{
  .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -30px !important;
  }
}
