@import "./common/scss/global.scss";

.app {
  height: 100vh;
  width: 100vw;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  position: relative;

  @media only screen and (max-width: 500px) {
    padding: 0 10px;
  }

  & > .overlay{
    position: relative;
    z-index: 3;
    padding: 2px;
    border-radius: $radius;
    display: flex;
    align-content: center;
    justify-content: center;
    max-width: 800px;
    min-height: 500px;
    width: 100%;
    max-height: 80vh;

    & .project-logo{
      display: none;
    }

    & > .innerbox{
      background: $white;
      border-radius: $radius;
      opacity: 0.95;
      width: 100%;

      & .planbot-icon{
        position: absolute;
        right: -19px;
        top: -32px;
        z-index: 4;
        width: 40px;

        @media only screen and (max-width: 500px) {
          display: none;
        }
      }

      & > div{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;

        @media only screen and (max-width: 375px) {
          padding: 10px;
        }

        & > .content{
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          & .form{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            max-width: 600px;

            & .form-field{
              &:not(:last-child){
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 500px) {
      height: calc( 100vh - 35px );
      max-height: unset;
      margin-top: 10px;

      & .project-logo{
        display: block;
        z-index: 2;
        object-fit: cover;
        object-position: bottom;
        height: 70px;
        margin: 0 auto;
      }

      & > .innerbox{
        overflow: auto;
        display: flex;
        flex-direction: column;

        & > div{
          height: unset;
          flex-grow: 1;
        }
      }
    }
  }

  .header-bar{
    position: absolute;
    left:0;
    top:0;
    background: $white;
    width: 100%;
    z-index: 1;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 15px rgba(0,0,0,.2);

    & > .project-logo{
      height: 90%;
    }
  
    @media only screen and (max-width: 500px) {
      display: none;
    }
  }

  .bottom-waves{
    position: absolute;
    left:0;
    bottom:0;
    width: 100%;
    z-index: 1;
    object-fit: cover;
    object-position: top;
    height: 150px;
  }

  .powered-by{
    position: absolute;
    left:50%;
    bottom:20px;
    transform: translateX( -50% );
    width: 15vw;
    min-width: 120px;
    max-width: 200px;
    z-index: 2;

    @media only screen and (max-width: 500px) {
      bottom:4px;
      width: auto;
      height: 15px;
    }
  }

  @media only screen and (max-width: 500px) {
    justify-content: flex-start;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
