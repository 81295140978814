@import "../../../common/scss/global.scss";

.navigation{
  display: flex;
  justify-content: center;

  & .wrapper{
    position: relative;

    @media only screen and (max-width: 360px) {
      display: flex;
    }

    & > div{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 10px;

      & i{
        margin-left: 10px;
      }
    }

    .previous-page{
      position: absolute;
      left: -90px;
      padding: 0 20px;

      & i{
        display: none;
      }
      
      @media only screen and (max-width: 500px) {
        left: -50px;
        background: transparentize( $primary, 0.95);
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        & i{
          margin-left: 0;
          display: block;
        }

        & span{
          display: none;
        }
      }
    }

    .next-page {
      background: $primary;
      color: $white;
      width: 250px;
      padding: 0 30px;
      white-space: nowrap;

      @media only screen and (max-width: 375px) {
        width: auto;
      }

      @media only screen and (max-width: 360px) {
        padding: 0 20px;
      }

      &:not(.disabled):hover {
        background: #2f1330;
      }

      &.disabled{
        background: rgba($primary, 0.30);
      }
    }
  }

}
